import React, { useEffect, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import { Spin } from "antd";
import { twoUserIcon } from "../assets";
import {
  StateWiseCandidateChart,
  RecruiterStatusChart,
  DesignationWiseChart,
  AgeOfCandidateChart,
  CandidateExperienceChart,
  SalaryWiseCandidateChart,
} from "../components/Charts";
import { useDashboardData } from "../hooks/useDashboardData";
import Toast from "../components/Toast/Toast";
import "../styles/DashboardPage.css";
import { useResponsive } from "../hooks/useResponsive";

const StatCard = ({ icon, title, value }) => (
  <div className="stats-card">
    <div>
      <img src={icon} alt={`${title} icon`} />
    </div>
    <div className="stats-right-content">
      <div className="title">{title}</div>
      <div className="value">{value}</div>
    </div>
  </div>
);

const Dashboard = () => {
  const userType = localStorage.getItem("__user_type__");
  const { isMobile } = useResponsive();

  const {
    dashboardCount,
    stateWiseCandidateData,
    recruiterStatusData,
    candidateWithExperienceData,
    salaryWiseCandidateData,
    ageOfCandidateData,
    designationWiseCandidateData,
    loading,
    error,
  } = useDashboardData(userType);

  // Error Handling
  useEffect(() => {
    if (error) {
      Toast({
        message:
          error.message || "An error occurred while fetching dashboard data",
        type: "error",
      });
    }
  }, [error]);

  // Memoized Stats Cards
  const statsCards = useMemo(
    () => [
      {
        title: "Total Jobs",
        value: dashboardCount.totalJobs,
        icon: twoUserIcon,
      },
      {
        title: "Total Candidate",
        value: dashboardCount.totalCandidate,
        icon: twoUserIcon,
      },
      {
        title: "Total Client",
        value: dashboardCount.totalClient,
        icon: twoUserIcon,
      },
      {
        title: "Total Recruiter",
        value: dashboardCount.totalRecruiter,
        icon: twoUserIcon,
      },
    ],
    [dashboardCount]
  );

  // Render Loading State
  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="header">
        <div>
          <div className="header-title">Your Dashboard</div>
          <div className="header-subtitle">
            Your command center for data-driven decisions.
          </div>
        </div>
      </div>

      <div className="stats-cards">
        {statsCards.map((card, index) => (
          <StatCard
            key={index}
            title={card.title}
            value={card.value}
            icon={card.icon}
          />
        ))}
      </div>

      <div className="charts-section">
        <StateWiseCandidateChart
          data={stateWiseCandidateData}
          loading={loading}
        />

        <div className="flex-row-container">
          {userType === "admin" && (
            <RecruiterStatusChart
              data={recruiterStatusData}
              isMobile={isMobile}
            />
          )}

          {userType === "recruiter" && (
            <DesignationWiseChart
              data={designationWiseCandidateData}
              loading={loading}
            />
          )}

          <AgeOfCandidateChart
            data={ageOfCandidateData}
            isMobile={isMobile}
            loading={loading}
          />
        </div>

        <div className="flex-row-container">
          <CandidateExperienceChart
            data={candidateWithExperienceData}
            isMobile={isMobile}
            loading={loading}
          />

          <SalaryWiseCandidateChart
            data={salaryWiseCandidateData}
            loading={loading}
          />
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Dashboard;
