import React from 'react';
import { Spin } from 'antd';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Pie,
  PieChart,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// Shared custom tooltip component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

// Constant color palettes
const COLORS = {
  RECRUITER_STATUS: ["#00C49F", "#FF8042"],
  AGE_COLORS: [
    "#8884d8", "#83a6ed", "#8dd1e1", 
    "#82ca9d", "#a4de6c", "#ffc658"
  ],
  DEFAULT_BAR: "#33ced5"
};

// Helper functions for labels
const createPieLabel = (RADIAN) => ({
  renderCustomizedLabel: ({
    cx, cy, midAngle, innerRadius, outerRadius, value, percent
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percent ? `${(percent * 100).toFixed(0)}%` : value}
      </text>
    );
  }
});

export const StateWiseCandidateChart = ({ data, loading }) => (
  <div className="chart-container">
    <div className="chart-title">State wise Candidate</div>
    {loading ? (
      <div className="loading-container">
        <Spin size="large" />
      </div>
    ) : (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Candidate Count" fill={COLORS.DEFAULT_BAR} />
        </BarChart>
      </ResponsiveContainer>
    )}
  </div>
);

export const RecruiterStatusChart = ({ data, isMobile }) => {
  const RADIAN = Math.PI / 180;
  const { renderCustomizedLabel } = createPieLabel(RADIAN);

  return (
    <div
      className="chart-container"
      style={{ width: "50%", height: isMobile ? "330px" : "270px" }}
    >
      <div className="chart-title">Recruiter Status</div>
      {data.length === 0 ? (
        <div className="loading-container">
          <Spin size="large" />
        </div>
      ) : (
        <ResponsiveContainer
          width="100%"
          height="75%"
          style={{ marginTop: "17px" }}
        >
          <PieChart>
            <Pie
              data={data}
              innerRadius={60}
              outerRadius={100}
              fill="#8884d8"
              dataKey="count"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS.RECRUITER_STATUS[index % COLORS.RECRUITER_STATUS.length]}
                />
              ))}
            </Pie>
            <Legend
              iconSize={20}
              layout={isMobile ? "horizontal" : "vertical"}
              verticalAlign={isMobile ? "bottom" : "middle"}
              wrapperStyle={{
                top: isMobile ? "100%" : "50%",
                right: isMobile ? -10 : 30,
                transform: "translate(0, -50%)",
                lineHeight: "24px",
                padding: 10,
                gap: 25,
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export const DesignationWiseChart = ({ data, loading }) => (
  <div
    className="chart-container"
    style={{ width: "50%", height: "270px" }}
  >
    <div className="chart-title">Designation wise Candidate</div>
    {loading ? (
      <div className="loading-container">
        <Spin size="large" />
      </div>
    ) : (
      <ResponsiveContainer
        width="100%"
        height="75%"
        style={{ marginTop: "17px" }}
      >
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Candidate Count" fill={COLORS.DEFAULT_BAR} />
        </BarChart>
      </ResponsiveContainer>
    )}
  </div>
);

export const AgeOfCandidateChart = ({ data, isMobile, loading }) => {
  const RADIAN = Math.PI / 180;
  const { renderCustomizedLabel } = createPieLabel(RADIAN);

  return (
    <div
      className="chart-container"
      style={{ width: "50%", height: isMobile ? "350px" : "270px" }}
    >
      <div className="chart-title">Age of Candidate</div>
      {loading ? (
        <div className="loading-container">
          <Spin size="large" />
        </div>
      ) : (
        <ResponsiveContainer
          width="100%"
          height="75%"
          style={{ marginTop: "17px" }}
        >
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={100}
              fill="#8884d8"
              dataKey="count"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS.AGE_COLORS[index % COLORS.AGE_COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              layout={isMobile ? "horizontal" : "vertical"}
              verticalAlign={isMobile ? "bottom" : "middle"}
              iconSize={18}
              formatter={(value) => (
                <span style={{ fontSize: "14px" }}>{value}</span>
              )}
              wrapperStyle={{
                top: isMobile ? "100%" : "50%",
                right: 35,
                transform: "translate(0, -50%)",
                lineHeight: "24px",
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export const CandidateExperienceChart = ({ data, isMobile, loading }) => (
  <div
    className="chart-container"
    style={{ width: "50%", height: isMobile ? "370px" : "300px" }}
  >
    <div className="chart-title">Candidate with Experience</div>
    {loading ? (
      <div className="loading-container">
        <Spin size="large" />
      </div>
    ) : (
      <ResponsiveContainer
        width="100%"
        height={350}
        style={{ marginTop: "-40px" }}
      >
        <RadialBarChart
          cx="50%"
          cy="50%"
          innerRadius="10%"
          outerRadius="80%"
          barSize={15}
          data={data}
        >
          <RadialBar
            minAngle={15}
            label={{ position: "insideStart", fill: "#ffff" }}
            background
            clockWise
            dataKey="count"
          />
          <Legend
            iconSize={15}
            layout={isMobile ? "horizontal" : "vertical"}
            verticalAlign={isMobile ? "bottom" : "middle"}
            wrapperStyle={{
              top: isMobile ? "100%" : "50%",
              right: 30,
              transform: "translate(0, -50%)",
              lineHeight: "24px",
            }}
          />
        </RadialBarChart>
      </ResponsiveContainer>
    )}
  </div>
);

export const SalaryWiseCandidateChart = ({ data, loading }) => (
  <div
    className="chart-container"
    style={{ width: "50%", height: "300px" }}
  >
    <div className="chart-title">Salary wise Candidate</div>
    {loading ? (
      <div className="loading-container">
        <Spin size="large" />
      </div>
    ) : (
      <ResponsiveContainer width="100%" height="90%">
        <ComposedChart
          layout="vertical"
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 15,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" scale="band" />
          <Tooltip />
          <Legend />
          <Bar dataKey="Candidate Count" barSize={15} fill={COLORS.DEFAULT_BAR} />
        </ComposedChart>
      </ResponsiveContainer>
    )}
  </div>
);