import { useState, useEffect } from 'react';
import { get } from '../lib/Api';

export const useDashboardData = (userType) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardCount, setDashboardCount] = useState({
    totalJobs: "",
    totalCandidate: "",
    totalClient: "",
    totalRecruiter: "",
  });
  const [stateWiseCandidateData, setStateWiseCandidateData] = useState([]);
  const [recruiterStatusData, setRecruiterStatusData] = useState([]);
  const [candidateWithExperienceData, setCandidateWithExperienceData] = useState([]);
  const [salaryWiseCandidateData, setSalaryWiseCandidateData] = useState([]);
  const [ageOfCandidateData, setAgeOfCandidateData] = useState([]);
  const [designationWiseCandidateData, setDesignationWiseCandidateData] = useState([]);

  const fetchData = async (url, setDataFn) => {
    try {
      const res = await get(url, null, {}, true);
      if (res?.status === true) {
        setDataFn(res.data);
      } else {
        throw new Error(res?.message || "Failed to fetch data");
      }
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        // Fetch dashboard counts
        const countsRes = await get(`user/dashboard/total-counts`, null, {}, true);
        if (countsRes?.status === true) {
          setDashboardCount({
            totalJobs: countsRes.data.jobs,
            totalCandidate: countsRes.data.candidates,
            totalClient: countsRes.data.clients,
            totalRecruiter: countsRes.data.recruiters,
          });
        } else {
          throw new Error(countsRes?.message || "Failed to fetch dashboard counts");
        }

        // Fetch other data in parallel
        await Promise.all([
          fetchData('user/dashboard/state-wise-candidate-count', setStateWiseCandidateData),
          fetchData('user/dashboard/experience-wise-candidate-count', setCandidateWithExperienceData),
          fetchData('user/dashboard/salary-wise-candidate-count', setSalaryWiseCandidateData),
          fetchData('user/dashboard/age-wise-candidate-count', setAgeOfCandidateData),
          
          // Conditional fetches based on user type
          userType === "admin" 
            ? fetchData('user/dashboard/recruiters-status-count', setRecruiterStatusData)
            : Promise.resolve(),
          
          userType === "recruiter"
            ? fetchData('user/dashboard/designation-wise-candidate-count', setDesignationWiseCandidateData)
            : Promise.resolve()
        ]);

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    loadDashboardData();
  }, [userType]);

  return {
    dashboardCount,
    stateWiseCandidateData,
    recruiterStatusData,
    candidateWithExperienceData,
    salaryWiseCandidateData,
    ageOfCandidateData,
    designationWiseCandidateData,
    loading,
    error
  };
};